import React, { Component, useEffect, useState } from "react";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import { Form } from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import LoadingContext from "../ToggleContext";
const mSwal = withReactContent(Swal)
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

export class AutomationEditor extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props)
   this.reset()
   this.componentDidMount()
  }
  reset() {
    this.state = {
      uuid:'',
      automation:null
    };
  }

  componentDidMount() {
    const autId = this.props.params.autId;
    if(autId!="new"){
      this.setState({uuid:autId})
      this.loadAutomation(autId);
    }else{
      this.setState({uuid:'new',automation:{attributes:{name:''}}})
      setTimeout(()=>{this.context.setLoading_(false);},1000)
    }
  }
  async loadAutomation(autId){
    let user = await User.get();
    let tempdata = await ApiService.get(`fairymailer/getAutomations?filters[uuid]=${autId}&populate=*`,user.jwt);
    if(tempdata && tempdata.data && tempdata.data.data){
      console.log('automation',tempdata.data.data[0])
      this.setState({automation:tempdata.data.data[0]})
    }
    this.context.setLoading_(false);
  }
  async saveAut(){
    let user = await User.get();
    let aut = this.state.automation;
    let tempdata;
    if(!aut.id || aut.attributes.uuid.length<1){
      aut.attributes.uuid = uuidv4();
      aut.attributes.account = user.account.id
      tempdata = await ApiService.post(`automations/`,{data:aut.attributes},user.jwt);
    }else{
      tempdata = await ApiService.put(`automations/${aut.id}`,{data:aut.attributes},user.jwt);
    }
    mSwal.fire({icon:'success',timer:500}).then(()=>{
        window.location.href='/automations/all'  
    })
  }
  async editFlow(){
    let user = await User.get();
    let aut = this.state.automation;
    let tempdata;
    if(!aut.attributes.uuid || aut.attributes.uuid.length<1){
      aut.attributes.uuid = uuidv4();
      aut.attributes.account = user.account.id

      tempdata = await ApiService.post(`automations/`,{data:aut.attributes},user.jwt);
    }else{
      tempdata = await ApiService.put(`automations/${aut.id}`,{data:aut.attributes},user.jwt);
    }
    mSwal.fire({icon:'success',timer:500}).then(()=>{
        window.location.href=`/automations/flow/${aut.attributes.uuid}`  ;
    })
  }
  render() {
    const { automation, uuid } = this.state;
    return (
      <div className="campaign-editor-container"> 
        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  { uuid=="new" ? (<h2 className="">New Automation</h2>) : (<h2 className="">Editing { automation ? automation.name : 'automation'} </h2>) }
                </div>
                <div className="form-group">
                <Form.Group>
                  <label htmlFor="campaignTitle">Description</label>
                  <input type="text" name="" className="form-control form-control-lg" id="campaignTitle" value={automation?automation.name :''} onChange={evt => {automation.name=evt.target.value;this.setState({automation:automation})}}/>
                </Form.Group>

                  <div className="campaign-buttons">
                        <button className="btn btn-lg btn-primary" onClick={()=>{this.editFlow()}}>Edit flow</button>
                        <button className="btn btn-lg btn-success" onClick={()=>{this.saveAut()}}>Save and back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(AutomationEditor);
