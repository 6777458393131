import React, { Component, useEffect, useState } from "react";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Select from 'react-select'
import EmojiPicker from 'emoji-picker-react';
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import LoadingContext from '../ToggleContext';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
const mSwal = withReactContent(Swal)


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
window.copyToClipboard = (text) => {
  const input = document.createElement('textarea');
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
  Swal.close()
}

export class CampaignEditor extends Component {
  static contextType = LoadingContext

  constructor(props) {
    super(props)
    this.state = {loading:true};
    this.reset()
    this.getGroups();
  }




  reset() {
    
    const today = new Date();
    const nextThreeDays = new Date(today.setDate(today.getDate() + 3));
    this.state = {
      loading:true,
      account_id:0,
      cmpUdid:'',
      cmpName: '',
      cmpSubject: '',
      cmpSubjectB: '',
      cmpGroup: '',
      cmpDate: nextThreeDays,
      emojisOpen:false,
      emojisOpenB:false,
      cmpType:'basic',
      domain_verified:true,
      dkim_verified:true,
      email_verified:true,
    };
  }
  async componentDidMount() {
    this.context.setLoading_(true);
    const cmpId = this.props.params.cmpId;
    this.setState({cmpId:cmpId})
    let user = await User.get();
    this.setState({account_id:user.account.id})
    // if(user.account.domain_verified) this.setState({domain_verified:user.account.domain_verified});
    // if(user.account.dkim_verified) this.setState({dkim_verified:user.account.dkim_verified});
    if(cmpId){
      let tempdata = await ApiService.get(`fairymailer/getCampaigns?filters[uuid]=${this.props.params.cmpId}&populate=recp_groups`,user.jwt);
      if(tempdata && tempdata.data && tempdata.data.data){
        console.log('campaign',tempdata.data.data[0])
        this.state.campaign = tempdata.data.data[0];
        this.setState(this.state)
        this.setState({
          cmpUdid: this.props.params.cmpId.trim(),
          cmpName: this.state.campaign?.name,
          cmpSubject: this.state.campaign?.subject,
          cmpSubjectB: this.state.campaign?.subject_b,
          cmpDate: this.state.campaign?.date,
          cmpType: this.state.campaign?.type,
          cmpGroup: this.state.campaign?.recp_groups ? this.state.campaign?.recp_groups.map(gr=>{return {value:gr.id,label:gr.name};}) : [],
        })
        this.context.setLoading_(false);
        return;
      }else{
        mSwal.fire({icon:'error',text:'Failed to load campaign data.'});
        this.context.setLoading_(false);
        return;
      }
    }
    let account = await ApiService.get(`fairymailer/getAccount`,user.jwt)
    account = account.data;
    console.log('account',account)
    if(account){
      this.setState({domain_verified:account.domain_verified,dkim_verified:account.dkim_verified,email_verified:account.email_verified});
    } 
    console.log('domain',this.state.domain_verified,'dkim',this.state.dkim_verified)
    this.context.setLoading_(false);
    this.setState({loading:false});
  }

  updateInputValue(input,evt) {
    let st = {}
    if(input=="cmpDate"){
      st[input] = evt.target.value;
    }else if(input=="cmpGroup"){
      st[input] = evt
    }else{
      st[input] = evt.target.value;
    }
    this.setState(st);
  }
  async getGroups() {
    let user = User.get();
    let resp = await ApiService.get("fairymailer/getGroups?polulate=*", user.jwt);
    console.log(resp);
    if (resp.data && resp.data.data) {
      this.setState({ availGroups: resp.data.data ? resp.data.data.map(g=>{return {value:g.id,label:g.name}}) : [], meta:resp.data.meta });
    }
  }
  async saveEmail(){
    if(!this.state.cmpName || this.state.cmpName.length<1){mSwal.fire({icon:'error',text:'Please type in a name for this campaign.'});return;}
    if(!this.state.cmpSubject || this.state.cmpSubject.length<3){mSwal.fire({icon:'error',text:'Please type in a campaign subject.'});return;}
    if(!this.state.cmpDate || this.state.cmpDate.length<3){mSwal.fire({icon:'error',text:'You need to select a valid date for this campaign.'});return;}
    let user = await User.get();
    let data = {
      data:{
        udid:this.state.cmpUdid,
        name:this.state.cmpName,
        account:this.state.account_id,
        subject:this.state.cmpSubject,
        subject_b:this.state.cmpSubjectB,
        date:this.state.cmpDate,
        type:this.state.cmpType,
        status:'draft',
        recp_groups:this.state.cmpGroup ? this.state.cmpGroup.map(g=>{return {id:g.value}}) : [],
      }
    }
    let resp;
    if(this.state.campaign && this.state.campaign.id){
      resp = await ApiService.post(`fairymailer/updateCampaign`,data,user.jwt)
    }else{
      data.data.udid = uuidv4();
      resp = await ApiService.post(`fairymailer/createCampaign`,data,user.jwt);
    }
    console.log(resp)
    if(resp.data && resp.data.code==200){
      window.location.href = `/campaigns/drafts`
    }
  }
  async editEmail(type='A'){
    if(!this.state.cmpName || this.state.cmpName.length<1){mSwal.fire({icon:'error',text:'Please type in a name for this campaign.'});return;}
    if(!this.state.cmpSubject || this.state.cmpSubject.length<3){mSwal.fire({icon:'error',text:'Please type in a campaign subject.'});return;}
    if(!this.state.cmpDate || this.state.cmpDate.length<3){mSwal.fire({icon:'error',text:'You need to select a valid date for this campaign.'});return;}
    const cmpName =  this.state.cmpName;
    const cmpSubject =  this.state.cmpSubject;
    const cmpSubjectB =  this.state.cmpSubjectB;
    const cmpDate =  this.state.cmpDate;
    const cmpGroup =  this.state.cmpGroup ? this.state.cmpGroup.map(gr=>gr.value) : [];
    mSwal.fire({
      icon:'info',
      text:'Please wait...',
      didOpen: async () => {
        Swal.showLoading();
        let user = await User.get();
        let data = {
          data:{
            udid:this.state.cmpUdid,
            name:cmpName,
            subject:cmpSubject,
            subject_b:cmpSubjectB,
            date:cmpDate,
            type:this.state.cmpType,
            status:'draft',
            recp_groups:cmpGroup
          }
        }
        let resp
        if(this.state.campaign && this.state.campaign.id){
          resp = await ApiService.post(`fairymailer/updateCampaign`,data,user.jwt)
        }else{
          data.data.uuid = uuidv4();
          resp = await ApiService.post(`fairymailer/createCampaign`,data,user.jwt);
        }
        console.log(resp)
        if(resp.data && resp.data.udid){
          window.location.href = `/campaigns/editor/${resp.data.udid}/${'B'==type ? 'body-b': ''}`;
          return;
        }
        mSwal.fire({icon:'error',text:`Failed to save your changes. If this problem persists, contact our support team.`})
      }
    })
    
  }

  toggleEmojis = (evt,input='A')=>{
    if('A'==input){
      this.setState({emojisOpen:!this.state.emojisOpen})
    }
    if('B'==input){
      this.setState({emojisOpenB:!this.state.emojisOpenB})
    }
  }
  onEmojiClick = (emojiData , event, target='A') =>{
      if('A'==target){
        this.setState({emojisOpen:false,cmpSubject:`${this.state.cmpSubject} ${emojiData.emoji}`})
        }else{
        this.setState({emojisOpenB:false,cmpSubjectB:`${this.state.cmpSubjectB} ${emojiData.emoji}`})
      }
  }
  showPersonalizationPicker = ()=>{
    mSwal.fire({iconHtml:'',title:'Select a placeholder',text:'',html:`<p>Click on the tag you want, to copy it to your clipboard.</p><br><br><div class="tag-chips"><span class="tag-chip" onclick="copyToClipboard(this.innerText)">{{name}}</span><span class="tag-chip" onclick="copyToClipboard(this.innerText)">{{email}}</span><span class="tag-chip" onclick="copyToClipboard(this.innerText)">{{demo_var}}</span></div>`,showConfirmButton:false})
  }
  
  render() {
    const { campaigns, meta, error, emojisOpen, emojisOpenB, domain_verified, dkim_verified, email_verified } = this.state;  
    const {loading} = this.context;
    const loaded = (loading || this.state.loading);
    const today = new Date();
    const nextThreeDays = new Date(today.setDate(today.getDate() + 3));
    return (
      <div className="campaign-editor-container"> 
       {!domain_verified && !loaded ? (
          <h4 className="alert alert-danger">Warning: Your domain identity is not verified, no emails will be sent from this account until you verify your domain identity.</h4>
          ) : (
            !dkim_verified && !loaded ? (
            <h4 className="alert alert-warning">Warning: Your domain identity is verified, but you also need to add DKIM signature CNAME records otherwise your sent email will be marked as spam.<br></br> Please visit Account Settings > Domain & Identity.</h4>
            ) : !email_verified && !loaded ? (
            <h4 className="alert alert-warning">Warning: Your domain identity is verified, but you also need to verify your MAIL FROM address.<br></br> Please visit Account Settings > Domain & Identity.</h4>
          ) : ''
         )
        }
        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  {this.state.cmpId ? (this.state.campaign ? <h2><small>Editing</small> {this.state.campaign.name}</h2> : <Skeleton/>) : <h2 className="">New Campaign</h2>}
                </div>
                <div className="form-group">
                <Form.Group>
                  <label htmlFor="campaignTitle">Campaign Title</label>
                  <input type="text" name="" className="form-control form-control-lg" id="campaignTitle" value={this.state.cmpName} onChange={evt => this.updateInputValue('cmpName',evt)}/>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="campaignSubject">Subject</label>
                  <input type="text" name="" className="form-control form-control-lg" id="campaignSubject" value={this.state.cmpSubject} onChange={evt => this.updateInputValue('cmpSubject',evt)}/>
                  <button className="emojiPickerIcon" onClick={evt => this.toggleEmojis(evt)}></button>
                  <EmojiPicker open={emojisOpen} onEmojiClick={this.onEmojiClick}/> 
                  <button className="btn btn-default" onClick={evt => this.showPersonalizationPicker()}>Add Personalization</button>
                </Form.Group>
                <div className="row">
                    <div className="col-xs-12 col-md-3 form-group" style={{display:'Flex',justifyContent:'space-between',alignItems:'baseline'}}>
                    <label>Simple Campaign</label>
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        checked={this.state.cmpType=='absplit'}
                        onChange={(evt)=>{this.setState({cmpType:evt.target.checked ? 'absplit' : 'basic'})}}
                        />
                      <label>A/B Split Campaign</label>
                    </div>
                  </div>
                  {
                    this.state.cmpType=='absplit' ? (
                      <div className="row">
                        <div className="col-md-6">
                        <Form.Group>
                          <label htmlFor="campaignSubject">Subject (B)</label>
                          <input type="text" name="" className="form-control form-control-lg" id="campaignSubject" value={this.state.cmpSubjectB} onChange={evt => this.updateInputValue('cmpSubjectB',evt)}/>
                          <button className="emojiPickerIcon" onClick={evt => this.toggleEmojis(evt,'B')}></button>
                          <EmojiPicker open={emojisOpenB} onEmojiClick={(emoji,event) => {this.onEmojiClick(emoji,event,'B')}}/>
                          <button className="btn btn-default" onClick={evt => this.showPersonalizationPicker()}>Add Personalization</button>
                      </Form.Group>
                        </div>
                      </div>
                    ) : ''
                  }
                  <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="campaignGroup">Target Group</label>
                        <Select options={this.state.availGroups} theme={(theme) => ({
                            ...theme,
                            colors: {
                            ...theme.colors,
                              text: 'orangered',
                              primary25: '#0090e7',
                              primary: 'black',
                            },
                          })} isMulti className="form-control form-control-lg" id="campaignGroup" value={this.state.cmpGroup} onChange={evt => this.updateInputValue('cmpGroup',evt)}/>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <label htmlFor="campaignDate">When to send?</label>
                        {/* <input type="date" name="" className="form-control form-control-lg" id="campaignDate" value={this.state.cmpDate} onChange={evt => this.updateInputValue('cmpDate',evt)}/> */}
                        <Flatpickr
                            className="form-control form-control-lg"
                            data-enable-time
                            options={{ 
                              minDate: "today", 
                              minTime:`${new Date().getHours()+2}:00`,
                              defaultDate:`${nextThreeDays}`
                            }} 
                            value={this.state.cmpDate}
                            onChange={([date]) => {
                              this.setState({ cmpDate:date });
                              console.log(date)
                            }}
                          />
                      </Form.Group>
                    </div>
                    {/* <div className="col-md-3">
                      <Form.Group>
                      <label htmlFor="campaignTime">&nbsp;</label>
                        <input type="time" name="" className="form-control form-control-lg" id="campaignTime" value={this.state.cmpTime} onChange={evt => this.updateInputValue('cmpTime',evt)}/>
                      </Form.Group>
                    </div> */}
                  </div>

                  <div className="campaign-buttons">
                        <button className="btn btn-lg btn-success" onClick={()=>{this.saveEmail()}}>Save and Go back</button>
                        {
                          this.state.cmpType=='absplit' ? (
                            <div>
                              <button className="btn btn-lg btn-primary" onClick={()=>{this.editEmail()}}>Next: Edit Content (A)</button><br></br><br></br>
                              <button className="btn btn-lg btn-primary" onClick={()=>{this.editEmail('B')}}>Next: Edit Content (B)</button>
                            </div>

                          ) : (
                            <button className="btn btn-lg btn-primary" onClick={()=>{this.editEmail()}}>Next: Edit Content (Body)</button>
                          )
                        }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(CampaignEditor);
