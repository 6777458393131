import React, { createContext, useState } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const setLoading_ = (value) => {
    setLoading(value);
  };

  return (
    <LoadingContext.Provider value={{ loading, setLoading_ }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;