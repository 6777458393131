import React, { Component, useEffect, useState } from "react";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import LoadingContext from "../ToggleContext";
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mSwal = withReactContent(Swal)

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

export class SubscriberEditor extends Component {
  static contextType = LoadingContext;
  constructor(props) {
    super(props)
   this.reset()
  //  this.getGroups();
  }
  reset() {
    this.state = {
      subscriber:{},
      account_id:0  
    };
  }

  componentDidMount() {
    const subId = this.props.params.subId;
    if(subId && subId.length>0 && subId!='new') this.getSubGyBuid(subId);
    else window.location.href='/subscribers/all'
    let user = User.get();
    this.setState({account_id:user.account.id});
  }
  async getSubGyBuid(udid="") {
    let user = User.get();
    let resp = await ApiService.get(`fairymailer/getSubscribers/?filters[udid]=${udid}&populate=*`, user.jwt);
    this.context.setLoading_(false);
    if (resp.data && resp.data.data) {
      this.setState({ subscriber: resp.data.data[0] });
      console.log(this.state.subscriber)
    }
  }

  updateInputValue(input,evt) {
    let st = this.state.subscriber
    st[input] = evt.target.value;
    this.setState({subscriber:st});
  }

  async saveSub(){
    this.context.setLoading_(true);
    let user = await User.get();
    let resp = await ApiService.post(`fairymailer/updateSubscriber`,{data:this.state.subscriber},user.jwt);
    console.log(resp)
    if(resp.data && resp.data.code==200){
      window.location.href = `/subscribers/all`
    }
  }
  async deleteSub(){
    mSwal.fire({icon:'question',text:`Do you really want to remove subscriber ${this.state.subscriber.email}? This action will unsubscribe them from all groups until they subscribe again manually.`,showConfirmButton:false,showDenyButton:true,showCancelButton:true,denyButtonText:'Yes, REMOVE',cancelButtonText:'No, keep them.'}).then(async res=>{
      if(res.isDenied){
        this.context.setLoading_(true);
        let user = await User.get();
        let resp = await ApiService.post(`fairymailer/removeSubscriber`,{data:this.state.subscriber},user.jwt);
        console.log(resp)
        if(resp.data && resp.data.code==200){
          window.location.href = `/subscribers/all`
        }
      }
    })
  }
  render() {
    const { subscriber } = this.state;

    return (
      <div className="campaign-editor-container"> 
        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  <h2 className="">{
                    (subscriber && subscriber.id) ? <span><small>Editing</small> {subscriber.name}</span> : <span></span>
                  }</h2>
                </div>
                <div className="form-group row">
                <Form.Group className="col-xs-12 col-md-4">
                  <label htmlFor="subName">Name</label>
                  <input type="text" name="" className="form-control form-control-lg" id="subName" value={subscriber.name} onChange={evt => this.updateInputValue('name',evt)}/>
                </Form.Group>
                <Form.Group className="col-xs-12 col-md-4">
                  <label htmlFor="subEmail">Email</label>
                  <input type="text" name="" className="form-control form-control-lg" id="subEmail" value={subscriber.email} disabled  onChange={evt => this.updateInputValue('email',evt)}/>
                </Form.Group>
                <Form.Group className="col-xs-12 col-md-4">
                  <label htmlFor="subEmail">Groups</label>
                  <ul>
                    {subscriber && subscriber.groups && subscriber.groups.map(g=>{return <li>{g.name}</li>})}
                  </ul>
                </Form.Group>
                  <div className="campaign-buttons">
                        {/* <button className="btn btn-lg btn-primary" onClick={()=>{this.automation()}}>Create Automation</button> */}
                        <button className="btn btn-lg btn-danger" onClick={()=>{this.deleteSub()}}>REMOVE Subscriber</button>&nbsp;&nbsp;
                        <button className="btn btn-lg btn-success" onClick={()=>{this.saveSub()}}>Save Subscriber</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(SubscriberEditor);
