const cf_two_cols_tpl = `
<table data-gjs-highlightable="true" data-gjs-type="table" id="cf-two-cols" cellpadding="0" cellspacing="0" border="0" align="center" width="640" style="width:640px;min-width:640px" >
  <tbody data-gjs-type="tbody">
    <tr data-gjs-type="row">
    <td align="center" style="padding:0px 40px" >
      <table data-gjs-highlightable="true" data-gjs-type="table"  cellpadding="0" cellspacing="0" border="0" align="center" width="100%">
        <tbody data-gjs-type="tbody"> <tr data-gjs-type="row">
          <td align="left" style="font-family:'Playfair Display',sans-serif;font-size:17px;line-height:150%;color:#22222">
            <table data-gjs-highlightable="true" data-gjs-type="table"  cellpadding="0" cellspacing="0" border="0" align="left" style="border-collapse:collapse" class="m_406378558817242768mlContentTable m_406378558817242768marginBottom">
              <tbody data-gjs-type="tbody"><tr  data-gjs-type="row">
                <td align="center" id="m_406378558817242768imageBlock-12">
                  <img src="" border="0" alt="" width="267" style="display:block" class="CToWUd" data-bit="iit">
                </td>
              </tr>
            </tbody>
          </table>
          <h5 style="margin:0px 0px 15px 0px;font-family:'Poppins',sans-serif;font-size:23px;font-weight:700;line-height:150%;color:#000000;text-transform:none;font-style:normal;text-decoration:none;text-align:left">About the Book</h5>
          <p style="margin-top:0px;margin-bottom:10px;font-size:17px;line-height:150%;color:#22222"><strong><em>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</em></strong></p>
          <p style="margin-top:0px;margin-bottom:10px;font-size:17px;line-height:150%;color:#22222">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <p style="margin-top:0px;margin-bottom:10px;font-size:17px;line-height:150%;color:#22222">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </td>
        </tr>
      </tbody>
    </table>
    </td>
  </tr>
</tbody>
</table>

`;

export default cf_two_cols_tpl;;