import React, { Component, useEffect, useState } from "react";
import { Doughnut } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton'
import LoadingContext from "../ToggleContext";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const mSwal = withReactContent(Swal)

export class DomainIdentity extends Component {
  static contextType = LoadingContext

  constructor(props) {
    super(props)
    this.state = {
      campaigns: [],
      domain:'',
      domainVerified:false,
      dkimrecs:false,
      domain_locked:false,
      email_locked:false,
      sending_email:'',
      emailVerified:false,
      skeletons:[{},{},{},{},{}]
    };
  }

  componentDidMount() {
    this.fetchSendingIdentity();
    this.checkDomainVerified();
    this.getDomainDKIM();
  }
  async fetchSendingIdentity(){
    let user = await User.get();
    let account = await ApiService.get('fairymailer/getAccount',user.jwt);
    console.log(account.data.sending_identity);
    if(account.data && account.data.sending_identity && account.data.sending_identity.sending_email && account.data.sending_identity.sending_email.length>0){
      this.setState({email_locked:true,sending_email:account.data.sending_identity.sending_email});
      let emailstatus = await ApiService.get('aws/verify-email-status',user.jwt)
      console.log('emailstatus',emailstatus)
      if(emailstatus.data.data.validated){
        this.setState({emailVerified:emailstatus.data.data.validated})
      }
    }else{this.setState({emailVerified:false})}
  }
  async checkDomainVerified() {
    let user = User.get();
    let resp = await ApiService.get("aws/get-domain-status", user.jwt);
    console.log(resp)
    if(resp.data.code==207){
      //no domain declared.
    }else if(resp.data.code==200){
      this.setState({domainVerified:resp.data.data,domain:Object.keys(resp.data.data.VerificationAttributes)[0],domain_locked:true,emailVerified:false})
    }else{
      mSwal.fire({icon:'error',timer:3500});
    }
  }
  async getDomainDKIM() {
    let user = User.get();
    let resp = await ApiService.get("aws/get-domain-dkim", user.jwt);
    console.log(resp)
    if(resp.data.code==207){
      //no domain declared.
    }else if(resp.data.code==200){
      this.setState({dkimrecs:resp.data.data,domain_locked:true})
      this.context.setLoading_(true);
      await this.verifyDomainDKIM();
    }else{
      mSwal.fire({icon:'error',timer:3500});
    }
    this.context.setLoading_(false);
  }
  async verifyDomainDKIM() {
    let user = User.get();
    let resp = await ApiService.get("aws/verify-domain-dkim", user.jwt);
    console.log(resp)
    if(resp.data.code==207){
      //no domain declared.
    }else if(resp.data.code==200){
      this.setState({dkimstatus:resp.data.data,domain_locked:true})
    }else{
      mSwal.fire({icon:'error',timer:3500});
    }
    this.context.setLoading_(false);
  }
  updateInputValue(input,evt) {
    let st = {}
    st[input] = evt.target.value;
    this.setState(st);
  }
  async saveDomain(){
    this.context.setLoading_(true);
    if(!this.state.domain || this.state.domain.lenth<5 || !this.state.domain.includes('.')){
      mSwal.fire({icon:'warning',text:'Please type in a valid domain name.'})
      return;
    }
    let response = await ApiService.post(`aws/add-domain`,{domain_name:this.state.domain},await User.get().jwt);
    this.context.setLoading_(false);
    console.log(response.data)
    if(response.data && response.data.code==200 && response.data.data.VerificationToken){
      mSwal.fire({icon:'success',html:`<h4>Domain added!</h4><br>Please add the following TXT record to verify the identity:<br>NAME: _amazon.${this.state.domain}<br>VALUE: ${response.data.data.VerificationToken}`});
      this.componentDidMount();
    }else{
      mSwal.fire({icon:'error',text:'Failed to add new domain name. Please contact our support team.'});
    }
  }
  async saveEmail(){
    this.context.setLoading_(true);
    if(!this.state.sending_email || this.state.sending_email.lenth<5 || !this.state.sending_email.includes('.') || !this.state.sending_email.includes('@') || !this.state.sending_email.includes(this.state.domain)){
      mSwal.fire({icon:'warning',text:'Please type in a valid email address of your sending domain name.'})
      this.setState({email_locked:false});
      this.context.setLoading_(false);
      return;
    }
    let response = await ApiService.post(`aws/add-email`,{email:this.state.sending_email},await User.get().jwt);
    this.context.setLoading_(false);
    console.log(response.data)
    if(response.data && response.data.code==200 && response.data.data){
      mSwal.fire({icon:'success',html:`<h4>Email added!</h4><br>Please be sure to check your inbox for verification link.`});
      this.componentDidMount();
    }else{
      mSwal.fire({icon:'error',text:'Failed to add new email identity. Please contact our support team.'});
    }
  }
  async removeDomain(){
    this.context.setLoading_(true);
    if(!this.state.domain || this.state.domain.lenth<5 || !this.state.domain.includes('.')){
      mSwal.fire({icon:'warning',text:'No domain found to remove. Please contact our support team.'})
      return;
    }
    mSwal.fire({icon:'question',text:'This action is irriversible. If you want to add the same domain again you will need to re-validate it with new DNS records. Do you wish to continue?',showCancelButton:true,showConfirmButton:true,cancelButtonText:'Cancel',confirmButtonText:'Yes, Delete it.'}).then(async res=>{
      if(res.value){
        let response = await ApiService.post(`aws/remove-domain`,{domain_name:this.state.domain},await User.get().jwt);
        console.log(response)
        this.context.setLoading_(false);
      }
    })
  }
  render () {
    const { domain, domain_locked, email_locked, domainVerified, dkimrecs, dkimstatus,sending_email,emailVerified} = this.state;
    const { loading, setLoading_ } = this.context;
    return (
      <div className='domain-identity'>
        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center align-self-start">
                  <h3 className="mb-0">Sending domain & identity</h3>
                </div>
                <br></br>
                <div className="form-group">
                  <Form.Group>
                    <label htmlFor="campaignTitle">Type your sending domain:</label>
                    <input type="text" name="" disabled={loading || domain_locked} className="form-control form-control-lg" id="domainname" value={domain} onChange={evt => this.updateInputValue('domain',evt)}/>
                    { domainVerified && domainVerified.VerificationAttributes[domain].VerificationStatus=='Pending' ? (
                      <div>
                        <br></br>
                        <error style={{color:'Red'}}>Status: PENDING </error>
                        <p>Please make sure you have added the following (TXT) DNS record in order to verify your identity</p>
                        <p>NAME: <code>_amazon.{domain}</code></p>
                        <p>VALUE: <code>{domainVerified.VerificationAttributes[domain].VerificationToken}</code></p>
                        <p>You can detach this domain name by clicking <a href="#" onClick={()=>{this.removeDomain()}}>here</a>. Caution! this action will also invalidate all domain keys and you will have to re-validate the identity for this domain later.</p>
                        <br></br>
                        <br></br>
                      </div>
                      ) : '' }
                    { domainVerified && domainVerified.VerificationAttributes[domain].VerificationStatus=='Success' ? (
                      <div>
                        <br></br>
                        <error style={{color:'Green'}}>Status: Verified! </error>
                        <p>You can detach this domain name by clicking <a href="#" onClick={()=>{this.removeDomain()}}>here</a>. Caution! this action will also invalidate all domain keys and you will have to re-validate the identity for this domain later.</p>
                      </div>
                      ) : '' }
                      {!domainVerified ? (
                        <div>
                          <p></p>
                          <button className="btn btn-sm btn-success" onClick={()=>{this.saveDomain()}}>Save & Continue</button>
                        </div>
                  ) : ''}
                  </Form.Group>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
              <h3 className="mb-0">DKIM Verification for domain {domain}</h3>
              
                   {dkimstatus && dkimstatus.DkimAttributes[domain].DkimVerificationStatus && "Success"==dkimstatus.DkimAttributes[domain].DkimVerificationStatus ? (
                    <div>
                       <error style={{color:'Green'}}>Status: Verified! </error>
                    </div>
                  ) : (
                    <div>
                      <p>You will need to add these CNAME records to make sure your mail is not marked as spam.</p>            
                      {dkimrecs && dkimrecs.DkimTokens.map((rec,i)=>{
                        return <div className="dkim-record">
                          <p>Record #{i+1}:</p>
                          <p>NAME: <code>{rec}._domainkey.{domain}</code></p>
                          <p>VALUE: <code>{rec}.dkim.amazonses.com</code></p>
                        </div>
                      })}
                      
                      <br></br>
                    </div>
                  )}
                   {dkimstatus && dkimstatus.DkimAttributes[domain].DkimVerificationStatus && "Success"!=dkimstatus.DkimAttributes[domain].DkimVerificationStatus ? (
                    <div>
                      <br></br>
                      <br></br>
                      <h4>DKIM Verification status <span style={{color:'red'}}>Pending verification</span></h4>
                      <br></br>
                    </div>
                  ) : ''}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
              <Form.Group>
                    <p></p>
                    <p></p>
                    <h3>Sending email (MAIL FROM):</h3>
                    <input type="text" name="" disabled={loading || email_locked} className="form-control form-control-lg" id="sending_email" value={sending_email} onChange={evt => this.updateInputValue('sending_email',evt)}/>
                    { emailVerified && emailVerified.VerificationAttributes && emailVerified.VerificationAttributes[sending_email].VerificationStatus=='Pending' ? (
                      <div>
                        <br></br>
                        <error style={{color:'Red'}}>Status: PENDING </error>
                        <p>Please check your inbox for verification link.</p>
                      </div>
                      ) : '' }
                    {!emailVerified && !email_locked ? (
                        <div>
                          <p></p>
                          <button className="btn btn-sm btn-success" onClick={()=>{this.saveEmail()}}>Save & Continue</button>
                        </div>
                    ) : ''}
                    { emailVerified ? (
                      <div>
                        <br></br>
                        <error style={{color:'Green'}}>Status: Verified! </error>
                        {/* <p>Your domain name identity is now verified.</p>
                        <p>You can detach this domain name by clicking <a href="#" onClick={()=>{this.removeDomain()}}>here</a>. Caution! this action will also invalidate all domain keys and you will have to re-validate the identity for this domain later.</p> */}
                      </div>
                      ) : <div>
                          <error style={{color:'red'}}>Status: Pending verification </error>
                        </div> }
                  </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div> 
    );
  }
}

export default DomainIdentity;