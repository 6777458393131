import React, { Component, useEffect, useState } from "react";
import User from "../services/User";
import {ApiService} from "../services/ApiService";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Doughnut, Pie } from 'react-chartjs-2';

import LoadingContext from '../ToggleContext';
const mSwal = withReactContent(Swal)


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
window.copyToClipboard = (text) => {
  const input = document.createElement('textarea');
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
  Swal.close()
}

export class CampaisnResults extends Component {
  static contextType = LoadingContext

  constructor(props) {
    super(props)
    this.state = {};
    this.reset()
  }

  opensData =  {
    labels: ["Opened", "Skipped"],
    datasets: [{
        data: [30, 70],
        backgroundColor: [
          "#553344","#883344"
        ]
      }
    ]
  };

  transactionHistoryData =  {
    labels: ["Mobile", "Desktop","Tablet"],
    datasets: [{
        data: [55, 35, 10],
        backgroundColor: [
          "#445566","#883344","#ffab00"
        ]
      }
    ]
  };


  transactionHistoryOptions = {
    responsive: true,
    maintainAspectRatio: true,
    segmentShowStroke: false,
    // cutoutPercentage: 70,
    elements: {
      arc: {
          borderWidth: 0
      }
    },      
    legend: {
      display: true
    },
    tooltips: {
      enabled: true
    }
  }



  reset() {

    const today = new Date();
    const nextThreeDays = new Date(today.setDate(today.getDate() + 3));
    this.state = {
      cmpName: '',
      cmpSubject: '',
      cmpSubjectB: '',
      cmpGroup: '',
      cmpRecipients:0,
      cmpStats:{o:0},
      cmpDate: nextThreeDays,
      emojisOpen:false,
      emojisOpenB:false,
      cmpType:'basic'
    };
  }
  
  async componentDidMount() {
    this.context.setLoading_(true);
    const cmpId = this.props.params.cmpId;
    this.setState({cmpId:cmpId})
    let user = await User.get();
    if(cmpId){
      let tempdata = await ApiService.get(`campaigns?filters[uuid]=${this.props.params.cmpId}&populate=recp_groups`,user.jwt);
      if(tempdata && tempdata.data && tempdata.data.data){
        console.log('campaign',tempdata.data.data[0])
        this.state.campaign = tempdata.data.data[0];
        this.setState(this.state)
        this.setState({
          cmpName: this.state.campaign?.attributes.name,
          cmpSubject: this.state.campaign?.attributes.subject,
          cmpSubjectB: this.state.campaign?.attributes.subject_b,
          cmpStats: this.state.campaign?.attributes.stats,
          cmpRecipients: this.state.campaign?.attributes.recipients,
          cmpDate: this.state.campaign?.attributes.date,
          cmpType: this.state.campaign?.attributes.type,
          cmpGroup: this.state.campaign?.attributes.recp_groups ? this.state.campaign?.attributes.recp_groups.data.map(gr=>{return {value:gr.id,label:gr.attributes.name};}) : [],
        })
        this.context.setLoading_(false);
        this.opensData =  {
          labels: ["Opened", "Skipped"],
          datasets: [{
              data: [this.state.cmpStats.o, 100-this.state.cmpStats.o],
              backgroundColor: [
                "#111111","#00d25b"
              ]
            }
          ]
        };
        return;
      }else{
        mSwal.fire({icon:'error',text:'Failed to load campaign data.'});
        this.context.setLoading_(false);
        return;
      }
    }
    this.context.setLoading_(false);
  }


  
  render() {
    const { campaigns, meta, error, emojisOpen, emojisOpenB } = this.state;  
    const today = new Date();
    const nextThreeDays = new Date(today.setDate(today.getDate() + 3));
    return (
      <div className="campaign-editor-container"> 
        <div className="row">
          <div className="col-sm-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="card-title">
                  {this.state.cmpId ? (
                    this.state.campaign ? 
                    <h2><small>Results of </small> {this.state.campaign.attributes.name}<br></br><small style={{fontSize:'14px'}}>Subject: {this.state.campaign.attributes.subject}</small></h2> 
                    : <Skeleton/>
                    ) : <h2 className="">Loading...</h2>}
                </div>
                <div className="row cards">

                  <div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
                    <div className="card" style={{background:'#000'}}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-9">
                            <div className="d-flex align-items-center align-self-start">
                              <h3 className="mb-0">{ this.state.campaign?.attributes?.recipients ? this.state.campaign.attributes.recipients : <Skeleton/> }</h3>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="icon icon-box-success ">
                              <span className="mdi mdi-account-box icon-item"></span>
                            </div>
                          </div>
                        </div>
                        <h6 className="text-muted font-weight-normal">Recipients</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
                    <div className="card" style={{background:'#000'}}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-9">
                            <div className="d-flex align-items-center align-self-start">
                              <h3 className="mb-0">{ this.state.campaign?.attributes?.stats ? this.state.campaign.attributes.stats.o : <Skeleton/> }</h3>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="icon icon-box-success ">
                              <span className="mdi mdi-bookmark-check icon-item"></span>
                            </div>
                          </div>
                        </div>
                        <h6 className="text-muted font-weight-normal">Opens</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
                    <div className="card" style={{background:'#000'}}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-9">
                            <div className="d-flex align-items-center align-self-start">
                              <h3 className="mb-0">{ this.state.campaign?.attributes?.stats ? this.state.campaign.attributes.stats.or + '%' : <Skeleton/> }</h3>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="icon icon-box-success ">
                            <span className="mdi mdi-percent icon-item"></span>
                            </div>
                          </div>
                        </div>
                        <h6 className="text-muted font-weight-normal">Open Rate</h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
                    <div className="card" style={{background:'#000'}}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-9">
                            <div className="d-flex align-items-center align-self-start">
                              <h3 className="mb-0">{ this.state.campaign?.attributes?.stats ? this.state.campaign.attributes.stats.c : <Skeleton/> }</h3>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="icon icon-box-success ">
                              <span className="mdi mdi-mouse icon-item"></span>
                            </div>
                          </div>
                        </div>
                        <h6 className="text-muted font-weight-normal">Clicks</h6>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-4 col-lg-3 col-sm-6 grid-margin stretch-card">
                    <div className="card" style={{background:'#000'}}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-9">
                            <div className="d-flex align-items-center align-self-start">
                              <h3 className="mb-0">{ this.state.campaign?.attributes?.stats ? this.state.campaign.attributes.stats.cr + '%' : <Skeleton/> }</h3>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="icon icon-box-success ">
                            <span className="mdi mdi-percent icon-item"></span>
                            </div>
                          </div>
                        </div>
                        <h6 className="text-muted font-weight-normal">Clicks Rate</h6>
                      </div>
                    </div>
                  </div> */}

                </div>

                <div className="row cars-subs">

                  <div className="col-md-3 col-sm-6 grid-margin stretch-card">
                      <div className="card" style={{background:'#000'}}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-9">
                              <div className="d-flex align-items-center align-self-start">
                                <h3 className="mb-0">0.00%</h3>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="icon icon-box-success ">
                                <span className="mdi mdi-account-multiple-minus icon-item"></span>
                              </div>
                            </div>
                          </div>
                          <h6 className="text-muted font-weight-normal">Unsubscribed</h6>
                        </div>
                      </div>
                    </div>

                  <div className="col-md-3 col-sm-6 grid-margin stretch-card">
                      <div className="card" style={{background:'#000'}}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-9">
                              <div className="d-flex align-items-center align-self-start">
                                <h3 className="mb-0">0.00%</h3>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="icon icon-box-success ">
                                <span className="mdi mdi-account-off"></span>
                              </div>
                            </div>
                          </div>
                          <h6 className="text-muted font-weight-normal">Spam complaints</h6>
                        </div>
                      </div>
                    </div>

                  <div className="col-md-3 col-sm-6 grid-margin stretch-card">
                      <div className="card" style={{background:'#000'}}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-9">
                              <div className="d-flex align-items-center align-self-start">
                                <h3 className="mb-0">0.00%</h3>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="icon icon-box-success ">
                                <span className="mdi mdi-alert-circle"></span>
                              </div>
                            </div>
                          </div>
                          <h6 className="text-muted font-weight-normal">Bounce</h6>
                        </div>
                      </div>
                    </div>

                </div>

                <div className="row device-chart">
                    <div className="col-md-4">
                      <h3>Device opened</h3>
                      <Pie data={this.transactionHistoryData} options={this.transactionHistoryOptions} />
                    </div>
                    <div className="col-md-4">
                      <h3>Opens</h3>
                      <Doughnut data={this.opensData} options={this.transactionHistoryOptions} />
                    </div>
                </div>

                <div className="row row-links">
                  <div className="col-lg-6 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Link Activity</h4>
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Link</th>
                                  <th>Unique Clicks</th>
                                  <th>Total Clicks</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>https://......</td>
                                  <td>0</td>
                                  <td className="text-danger"> 0 <i className="mdi mdi-arrow-down"></i></td>
                                </tr>
                                <tr>
                                  <td>https://......</td>
                                  <td>0</td>
                                  <td className="text-danger"> 0 <i className="mdi mdi-arrow-down"></i></td>
                                </tr>
                                <tr>
                                  <td>https://......</td>
                                  <td>0</td>
                                  <td className="text-danger"> 0 <i className="mdi mdi-arrow-down"></i></td>
                                </tr>
                                <tr>
                                  <td>https://......</td>
                                  <td>0</td>
                                  <td className="text-danger"> 0 <i className="mdi mdi-arrow-down"></i></td>
                                </tr>
                              
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withParams(CampaisnResults);
