import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import CFMailEditor from './email-editor/Editor';
import AllCampaigns from './all-campaigns/AllCampaigns';
import CampaignEditor from './campaign-editor/CampaignEditor';
import CampaisnResults from './campaign-editor/CampaisnResults';
import AllGroups from './all-groups/AllGroups';
import GroupEditor from './group-editor/GroupEditor';
import AllSubscribers from './all-subscribers/AllSubscribers';
import Templates from './all-campaigns/Templates';
import AllAutomations from './all-automations/AllAutomations';
import AutomationEditor from './automation-editor/AutomationEditor';
import FlowEditor from './flow-editor/FlowEditor';
import DomainIdentity from './account/DomainIdentity';
import SubscriberEditor from './subscriber-editor/SubscriberEditor'
import UserEditor from './user-editor/UserEditor';
import User from "./services/User";


const Users = lazy(() => import('./all-users/AllUsers'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));

class AppRoutes extends Component {

  render ( ) {
    const user = User.get();
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/dashboard" component={ Dashboard } />

          <Route path="/campaigns/editor/:cmpId/:bodyType" component={ CFMailEditor } />
          <Route path="/campaigns/editor/:cmpId" component={ CFMailEditor } />
          <Route path="/campaigns/editor" component={ CFMailEditor } />
          <Route path="/campaigns/:cmpId/edit" component={ CampaignEditor } />
          <Route path="/campaigns/:cmpId/results" component={ CampaisnResults } />
          <Route path="/campaigns/new" component={ CampaignEditor } />
          <Route path="/campaigns/:cmpType" component={ AllCampaigns } />
          {/* <Route path="/campaigns/templates/:tplId" component={ Templates } /> */}
          <Route path="/templates/all" component={ Templates } />
          <Route path="/templates/edit/:tplId" component={ CFMailEditor } />
          <Route path="/subscribers/groups/:grpId" component={ GroupEditor } />
          <Route path="/subscribers/groups/new" component={ GroupEditor } />
          <Route path="/subscribers/groups" component={ AllGroups } />
          <Route path="/subscribers/:groupId" component={ AllSubscribers } />
          <Route path="/subscribers/all" component={ AllSubscribers } />
          <Route path="/subscribers/new" component={ AllGroups } />
          <Route path="/subscriber/:subId" component={ SubscriberEditor } />
          <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/typography" component={ Typography } />
          <Route path="/automations/all" component={ AllAutomations } />
          <Route path="/automations/edit/:autId" component={ AutomationEditor } />
          <Route path="/automations/flow/:autId" component={ FlowEditor } />
          <Route path="/form-Elements/basic-elements" component={ BasicElements } />

          { user && user.account.user_role=="admin" ? ( <Route path="/settings/identity" component={ DomainIdentity } /> ) : '' }
          { user && user.account.user_role=="admin" ? (  <Route path="/settings/users" component={ Users } /> ) : '' }
          { user && user.account.user_role=="admin" ? (  <Route path="/settings/user/new" component={ UserEditor } /> ) : '' }
          { user && user.account.user_role=="admin" ? (  <Route path="/settings/user/:usrId" component={ UserEditor } /> ) : '' }
         
          
          <Route path="/tables/basic-table" component={ BasicTable } />

          <Route path="/icons/mdi" component={ Mdi } />

          <Route path="/charts/chart-js" component={ ChartJs } />


          <Route path="/login" component={ Login } />
          <Route path="/user-pages/register-1" component={ Register1 } />

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />


          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;