import React, { Component, useRef, useState } from 'react';
import EmailEditor, { EditorRef, EmailEditorProps} from 'react-email-editor';
import { ApiService, BASE_URL, PIXEL_URL }  from '../services/ApiService';
import User  from '../services/User';
import { useParams } from 'react-router-dom';
import grapesjs from 'grapesjs';
import plugin from 'grapesjs-preset-newsletter';
import { v4 as uuidv4 } from 'uuid';
import LoadingContext from '../ToggleContext';
import cflogoimg from './cf-logo.component'
import divider from './cf-divider.component'
import cf_predef_header from './cf-predef-header.component'
import cf_precf_penname_logo from './cf-penname-logo.component'
import cf_text_template from './cf-text-template.component'
import cf_two_cols_tpl from './cf-two-cols-tpl.component'
import cf_read_book_button from './cf-read-book-button.component'
import 'grapesjs/dist/css/grapes.min.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import GoogleFontLoader from 'react-google-font-loader';
import { property } from 'lodash';

const mSwal = withReactContent(Swal)

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


export class CFMailEditor extends Component {
  static contextType = LoadingContext;

  constructor(props) {
    super(props)
    this.state = {
      editorType:'',
      uuid:'',
      entity:null
    };
  }

  
async componentDidMount() {
  
  if(this.props.params.tplId){
    this.setState({
      editorType:'template',
      uuid:this.props.params.tplId
    })
  }
  if(this.props.params.cmpId){
    this.setState({
      editorType:'campaign',
      uuid:this.props.params.cmpId
    })
  }
  console.log('this.props.params',this.props.params)
}


render() {
  const user = User.get();  
  const instance = this;
  const settingsPanel = (editor,component)=>{
      if(editor.StyleManager.getSector('decorations')) editor.StyleManager.removeSector('decorations')
      if(editor.StyleManager.getSector('typography')) editor.StyleManager.removeSector('typography')
      if(editor.StyleManager.getSector('dimension')) editor.StyleManager.removeSector('dimension')
      editor.StyleManager.addSector('dimensions',{
          open:false,
          name: 'Shape',
          properties: [
            { 
              type: 'radio', 
              label:'Alignment',
              property: 'text-align',
              default: 'center',
              options: [
                {value: 'left', label: 'Left'},{value: 'center', label: 'Center'},{value: 'right', label: 'Right'},{value:'Justify',label:'justify'}] 
            },
            {
              type: 'slider',
              label: 'Width',
              property: 'width',
              default: 'auto',
              units: ['px','%'],
              min: 0, 
              max:1920
            },
            {
              type: 'slider',
              label: 'Max Width',
              property: 'max-width',
              default: '100%',
              units: ['px','%'],
              min: 0, 
              max:1920
            },
            {
              type: 'slider',
              label: 'Height',
              property: 'height',
              default: 'auto',
              units: ['px','%'],
              min: 0, 
              max:1920
            },
            {
              type: 'slider',
              label: 'Max Height',
              property: 'max-height',
              default: '100%',
              units: ['px','%'],
              min: 0, 
              max:1920
            },
            {
              type: 'slider',
              label: 'Radius',
              property: 'border-radius',
              default: '0',
              units: ['px','%'],
              min: 0, 
              max:100
            },
          ],
      })
      editor.StyleManager.addSector('spacing',{
          open:false,
          name: 'Spacing',
          properties: [
            {
              id: 'padding-top',
              type: 'slider',
              label: 'Top Padding',
              property: 'padding-top',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              id: 'padding-left',
              type: 'slider',
              label: 'Left Padding',
              property: 'padding-left',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              id: 'padding-bottom',
              type: 'slider',
              label: 'Bottom Padding',
              property: 'padding-bottom',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              id: 'padding-right',
              type: 'slider',
              label: 'Right Padding',
              property: 'padding-right',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              type: 'slider',
              label: 'Top Margin',
              property: 'margin-top',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              type: 'slider',
              label: 'Bottom Margin',
              property: 'margin-bottom',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              type: 'slider',
              label: 'Left Margin',
              property: 'margin-left',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
            {
              type: 'slider',
              label: 'Right Margin',
              property: 'margin-right',
              default: '0',
              units: ['px', '%'],
              min: 0, 
              max:400
            },
          ],
      })
      editor.StyleManager.addSector('color',
        {
          open:false,
          name: 'Color & Border',
          properties: [
            {
              property: 'background-color',
              label: 'Background',
              type: 'color',
              default: '#ffffff00',
            },
            {
              property: 'color',
              label: 'Text Color',
              type: 'color',
              default: '#000000',
            },
            {
              property: 'border-color',
              label: 'Border Color',
              type: 'color',
              default: '#000000',
            },
            { 
              type: 'select', 
              label:'Border style',
              property: 'border-style',
              default: 'solid',
              options: [{id: 'solid', label: 'Solid'},{id: 'dashed', label: 'Dash'},{id: 'dotted', label: 'Dots'},] 
            },
            {
              type: 'composite',
              property:'border',
              label: 'Border',
              properties: [
                { type: 'number', units: ['px'], default: '0', property: 'border-top' },
                { type: 'number', units: ['px'], default: '0', property: 'border-right' },
                { type: 'number', units: ['px'], default: '0', property: 'border-bottom' },
                { type: 'number', units: ['px'], default: '0', property: 'border-left' },
              ]
            }
          ],
      })
      if(['link','text'].includes(component.attributes.type)){
        editor.StyleManager.addSector('typography',
        {
          open:false,
          name: 'Typography',
          properties: [
            {
              property: 'font-family',
              label: 'Font',
              type: 'select',
              default: `"Oswald", 'Arial', 'Helvetica', sans-serif;`,
              options:[{value: `"Oswald", sans-serif`, name: 'Oswald'},
              {value: `"Roboto", 'Arial', 'Helvetica', sans-serif;`, name: 'Roboto'},
              {value: `"Playfair Display", 'Georgia', 'Times New Roman', serif;`, name: 'Playfair Display'},
              {value: `"Ubuntu", 'Verdana', 'Arial', sans-serif;`, name: 'Ubuntu'},
              {value:'Arial', name:'Arial'},
              {value:'Helvetica', name:'Helvetica'},
              {value:'Times New Roman', name:'Times New Roman'},
              {value:'Courier New', name:'Courier New'},
              {value:'Georgia', name:'Georgia'},
              {value:'Trebuchet MS', name:'Trebuchet MS'},
              {value:'Verdana', name:'Verdana'},
              {value:'Tahoma', name:'Tahoma'},
              {value:'Palatino', name:'Palatino'},
              {value:'Impact', name:'Impact'},
            ]
            },
            {
              property:'font-size',
              label:'Font Size',
              type:'slider',
              default:'14px',
              units: ['px', 'pt'],
              min: 0, 
              max:100
            },
            {
              property:'line-height',
              label:'Line height',
              type:'slider',
              default:'1em',
              units: ['px', 'em'],
              min: 0, 
              step: 0.1,
              max: 100
            },
            {
              property:'letter-spacing',
              label:'Letter Spacing',
              type:'slider',
              default:'0',
              units: ['px', 'em'],
              min: 0, 
              step: 0.5,
              max: 100
            },
            {
              property:'font-weight',
              label:'Weight',
              type:'select',
              default:'normal',
              options:[
                {value:'200',label:'Light'},
                {value:'normal',label:'Normal'},
                {value:'bold',label:'Bold'},
              ]
            }
          ],
      })
      }
  }
  if(!this.state.entity) setTimeout( async ()=>{
    let blocks = [
      // {
      //   attributes: { class: 'fa fa-tablet' },
      //   id: 'cf-center-container',
      //   label: 'CF Center Container',
      //   content: `<div class="cf-center-container" style="min-height:150px;background:#FFF1D5;max-width:992px;margin: 0 auto;padding:2em;text-align:center;"></div>`,
      // },
      // {
      //   attributes: { class: 'fa fa-desktop' },
      //   id: 'cf-header-1',
      //   label: 'CF Header 1',
      //   content: cf_predef_header,
      // },
      // {
      //   label: 'Cobalt Fairy Logo',
      //   id:'cf-logo-image',
      //   attributes: { class: 'fa fa-image' },
      //   content: cflogoimg, 
      // },
      // {
      //   label: 'CF Pen Name Logo',
      //   id:'cf-penname-logo-image',
      //   attributes: { class: 'fa fa-image' },
      //   content: cf_precf_penname_logo, 
      // },
      // {
      //   label: 'CF Intro Template',
      //   id:'cf-text-template',
      //   attributes: { class: 'fa fa-align-justify' },
      //   content: cf_text_template, 
      // },
      // {
      //   attributes: { class: 'fa fa-window-minimize' },
      //   id: 'cf-divider',
      //   label: 'CF Divider',
      //   content: divider
      // },
      {
        attributes: { class: 'fa fa-book' },
        id: 'cf-two-cols',
        label: 'About the Book',
        content: cf_two_cols_tpl
      },
      // {
      //   attributes: { class: 'fa fa-amazon' },
      //   id: 'cf-read-button',
      //   label: 'CF Read Book Button',
      //   content: cf_read_book_button
      // },
    ];
    let customblocks = await ApiService.get(`accounts/${user.account.id}?fields=custom_components`, user.jwt);
    if(customblocks && customblocks.data.data.attributes.custom_components) customblocks  =customblocks.data.data.attributes.custom_components;
    customblocks = customblocks.map(i=>{i.content=decodeURIComponent(atob(i.content));return i;})
    blocks = blocks.concat(customblocks)
    const cdn_user = btoa(`${user.user.id}:${user.user.username}`);
    const editor = grapesjs.init({
      container : '#gjs',
      fromElement: true,
      storageManager: false,
      assetManager: {
        storageType      : '',
        storeOnChange    : true,
        storeAfterUpload  : true,   
        upload: `https://cdn.cobaltfairy.online/controller.php?user=${cdn_user}&cmp=${btoa(this.props.params.cmpId)}`, 
      },
      content:'',
      plugins: [ plugin ],
      pluginsOpts: {
        "gjs-preset-newsletter": {
          inlineCss: false,
        },
      },
      blockManager: {
        blocks: blocks
      }
    });
    editor.setComponents([])
    editor.setStyle(``)

    if(this.state.editorType=="campaign"){
      let campaign_data = await ApiService.get(`fairymailer/getCampaigns/?filters[uuid]=${this.state.uuid}`,user.jwt);
      console.log(`campaigns?filters[uuid]=${this.props.params.cmpId}`,campaign_data)
      if(campaign_data.data && campaign_data.data.data) campaign_data = campaign_data.data.data[0]; else campaign_data = null;
      const campaign = campaign_data;
      this.setState({entity:campaign})
      console.log('cmpdata',campaign)
      let campaign_data_design;
      if(this.props.params.bodyType && this.props.params.bodyType=='body-b'){
        if(null==campaign_data.design_b && null!=campaign_data.design){
          mSwal.fire({icon:'question',text:'Do you wish to copy the email body A and then edit it as version B?',showCancelButton:true,showConfirmButton:true,cancelButtonText:'No, use blank canvas',confirmButtonText:'Yes, copy version A'}).then(res=>{
            if(res.value){
              campaign_data_design = JSON.parse(campaign_data.design);
              if(campaign_data_design && campaign_data_design.components && campaign_data_design.style){
                editor.setComponents(campaign_data_design.components)
                editor.setStyle(campaign_data_design.style)
              }
            }
          })
        }
      }
      if(campaign_data) campaign_data_design = JSON.parse( (!this.props.params.bodyType || 'body-b'!=this.props.params.bodyType) ? campaign_data.design : campaign_data.design_b);
      if(campaign_data_design && campaign_data_design.components && campaign_data_design.style){
        console.log('campaign_data',campaign_data)
        editor.setComponents(campaign_data_design.components)
        editor.setStyle(campaign_data_design.style)
      }
    }else{
      let tplData = await ApiService.get(`fairymailer/getTemplates/?filters[uuid]=${this.state.uuid}`,user.jwt);
      tplData = tplData.data.data[0];
      this.setState({entity:tplData})
      console.log('template',tplData)
      let design = tplData.design;
      design = JSON.parse(design)
      if(design && design.components && design.style){
        editor.setComponents(design.components)
        editor.setStyle(design.style)
      }
    }
    this.context.setLoading_(false);
    
  editor.on('component:selected', (component) => {
    console.log(component.attributes.type)
    settingsPanel(editor,component);
    //save element icon
    setTimeout(()=>{
      window.gjs_selected = component;
      let toolbar = editor.editor.view.$el[0].querySelector('.gjs-toolbar-items');
      if(toolbar){
        var newElement = document.createElement("div");
        newElement.classList.add("gjs-toolbar-item", "floppy-save");
        newElement.onclick = function() {
          window.saveElement();
        };
        var iconElement = document.createElement("i");
        iconElement.classList.add("fa", "fa-floppy-o");
        newElement.appendChild(iconElement);
        toolbar.prepend(newElement)
      }
    },100)
  });
  
  window.copyToClipboard = (text) => {
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    Swal.close()
}
  window.saveElement = async ()=>{
    let html = window.gjs_selected.view.$el[0].innerHTML;
    let css = window.gjs.getSelected().getStyle();
    let stylestr = ``;
    Object.keys(css).forEach(item=>{
      stylestr+=`${item}:${css[item]};`
    })
    html = `<div style="${stylestr}">${html}</div>`
    console.log('in save element',html)
    if(html && html.length>0){
      mSwal.fire(
        {
          title: "Type a name for this element:",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonText: "Save",
          showLoaderOnConfirm: true,
          preConfirm: async (input) => {
            try {
              let user = JSON.parse(decodeURIComponent(localStorage.getItem("cfmmuser")))
              let component = {
                attributes: { class: 'fa fa-th-large' },
                id: 'cf-custom-'+btoa(new Date().getTime()),
                label: input,
                content: btoa(encodeURIComponent(html)),
              }
              fetch(`${BASE_URL}/accounts/${user.account.id}`, {
                method: 'GET',
                headers: new Headers({ 'Authorization': `Bearer ${user.jwt}`})
                // body: JSON.stringify(requestData) 
              })
              .then(response => {
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json(); // or response.text() depending on the expected response format
              }).then(data => {
                if(!data || !data.data || !data.data.attributes){alert('Failed to save new element.');return;}
                if(!data.data.attributes.custom_components) data.data.attributes.custom_components=[];
                // component.label += data.data.attributes.custom_components.length+1;
                data.data.attributes.custom_components.push(component)
                fetch(`${BASE_URL}/accounts/${user.account.id}`, {
                  method: 'PUT',
                  headers: new Headers({ 'Authorization': `Bearer ${user.jwt}`, 'Content-type':'application/json'}),
                  body: JSON.stringify({data:{custom_components:data.data.attributes.custom_components}})
                }) .then(response => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }
                  return response.json(); // or response.text() depending on the expected response format
                })
                .then(data => {
      
                  console.log('after save response.',data);
                  component.content = decodeURIComponent(atob(component.content))
                  window.gjs.BlockManager.blocks.push(component)
                  mSwal.fire({icon:'success',text:'Element saved!',timer:900});
                });
              })
              .catch(error => {
                // Handle any errors that occurred during the fetch
                console.error('Fetch error:', error);
              });
            } catch (error) {
              Swal.showValidationMessage(`
                Request failed: ${error}
              `);
            }
          },
          allowOutsideClick: false
        }).then((result) => {
          // console.log('result',result)
          // mSwal.fire({icon:"success",timer:1200,text:`Element saved!`});
          // if(result.value && result.value.data.data && result.value.data.data.id){
          // }
        }
        )
      
      

    }
  }

  const customPanel = editor.Panels.addPanel({
    id: 'custom-panel',
    after: 'devices-c',
    buttons: [
      {
        id: 'save-db',
        className: 'btn btn-success fa fa-floppy-o icon-blank',
        command: 'save-template',
        // content: 'Save',
        attributes: {title: 'Save Campaign Body'}
      },
      {
        id: 'tag-name',
        className: 'fa fa-code icon-blank',
        command: 'tag-name',
        attributes: {title: 'Add Placeholder'}
      },
      {
        id: 'save-tpl',
        className: 'fa fa-cloud-upload',
        command: 'save-tpl',
        attributes: {title: 'Save as template'}
      },
      {
        id: 'import-tpl',
        className: 'fa fa-cloud-download',
        command: 'import-tpl',
        attributes: {title: 'Import template'}
      }
    ],
  });

  editor.Commands.add('save-template', {
      run: async function(editor, sender)
      {
        sender && sender.set('active'); // turn off the button
        let components = editor.getComponents();
        let style = editor.getStyle()
        let templateData = { 
            components: components,
            style: style
        };
        localStorage.setItem('last-gjs-tpl', JSON.stringify(templateData));
        console.log(JSON.parse(JSON.stringify(templateData))) 
        let resp = await saveTemplate(templateData);
      }
  });
  editor.Commands.add('tag-name', {
      run: async function(editor, sender)
      {
        sender && sender.set('active'); // turn off the button
        let components = editor.getComponents();
        mSwal.fire({iconHtml:'',title:'Select a placeholder',text:'',html:`<p>Click on the tag you want, to copy it to your clipboard.</p><br><br><div class="tag-chips"><span class="tag-chip" onclick="window.copyToClipboard(this.innerText)">{{name}}</span><span class="tag-chip" onclick="window.copyToClipboard(this.innerText)">{{email}}</span><span class="tag-chip" onclick="window.copyToClipboard(this.innerText)">{{demo_var}}</span></div>`,showConfirmButton:false})
      }
  });
  editor.Commands.add('save-tpl', {
      run: async function(editor, sender)
      {
        sender && sender.set('active'); // turn off the button
        mSwal.fire(
          {
            title: "Save template as:",
            input: "text",
            inputAttributes: {
              autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Save",
            showLoaderOnConfirm: true,
            preConfirm: async (input) => {
              try {
                let components = editor.getComponents();
                let style = editor.getStyle()
                let templateData = { 
                  components: components,
                  style: style
                };
                let resp = await saveAsNewTemplate(templateData,`<html><head>${editor.getStyle()}</head><body>${editor.getHtml()}</body></html>`,input);
                return resp
              } catch (error) {
                Swal.showValidationMessage(`
                  Request failed: ${error}
                `);
              }
            },
            allowOutsideClick: false
          }).then((result) => {
            if(result.value && result.value.data.data && result.value.data.data.id){
              mSwal.fire({icon:"success",timer:1200,text:`Template "${result.value.data.data.attributes.name}" saved!`});
            }
            console.log('result',result)
          }
          )
      }
  });
  editor.Commands.add('import-tpl',{
    run: async function(editor, sender)
    {
      sender && sender.set('active'); // turn off the button
      mSwal.fire({
        icon:'info',
        text:'Fetching templates...',
        didOpen: async () => {
          instance.context.setLoading_(true);
          Swal.showLoading();
          let templates = await ApiService.get(`fairymailer/getTemplates/?pagination[pageSize]=100&sort[createdAt]=desc`,await User.get().jwt);
          console.log(templates.data.data)
          mSwal.fire({icon:'success',html: `
            <select id="swal-select" class="swal2-select">
                ${templates.data.data.map(tpl=>{return `<option value="${tpl.udid}">${tpl.name}</option>`})}
            </select>
        `,focusConfirm: false,
        preConfirm: () => {
            const selectedOption = Swal.getPopup().querySelector('#swal-select').value;
            return selectedOption;
        }}).then(async res=>{
          if(res && res.value){
            mSwal.fire({
              icon:'info',
              text:'Loading data...',
              didOpen: async () => {
                Swal.showLoading();
                let tpl = await ApiService.get(`fairymailer/getTemplates/?filters[udid]=${res.value}`,await User.get().jwt);
                console.log('template',tpl)
                tpl = tpl.data.data[0]
                let design = tpl.design;
                design = JSON.parse(design)
                if(design && design.components && design.style){
                  editor.setComponents(design.components)
                  editor.setStyle(design.style)
                }
                instance.context.setLoading_(false);
                mSwal.fire({icon:'success',timer:500})
              }
            });
            //
          }

        })
        
          
        }
      })
    }
  })
  editor.on('storage:load', function(e) { console.log('Loaded ', e);});
  editor.loadData()

  const saveAsNewTemplate = async (templateData,html,name) =>{
    return await ApiService.post(`templates`, {data:{name:name,design:JSON.stringify(templateData),uuid:uuidv4(),html:html,account:user.account.id}}, user.jwt);
  }
  const saveTemplate = async (templateData) =>{
    const allFonts = `
          @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
    `
    const requiredStyles=`
    <style>
          ${allFonts}
          body, *{
            font-family:'Playfair Display', arial
          }
          .button{padding: 10px 25px;background-color:#333;border: 1ox solid #333;border-radius:10px;color:white}
          a{  text-decoration-line: none!important; }
          #cf-two-cols img{ margin-right:20px;}
        </style>
    `;
    mSwal.fire({
      icon:'info',
      text:'Please wait...',
      didOpen: async () => {
        this.context.setLoading_(true);
        Swal.showLoading();
        let data_to_save = {design:JSON.stringify(templateData)};
        if(this.props.params.bodyType && this.props.params.bodyType=='body-b'){
          data_to_save = {design_b:JSON.stringify(templateData)}
        }
        let resp = await ApiService.put(`${this.state.editorType=="campaign" ? 'campaigns' : 'templates'}/${this.state.entity.id}`, {data:data_to_save}, await User.get().jwt);
        let pixel = `${PIXEL_URL}/custom/pixel.gif?${(this.state.editorType=="campaign" ? 'cid' : 'tid')}=${this.state.uuid}&uid={{pixel_uid}}`;
        const regex = /https?:\/\/(\S*)/gm; // regex to find links
        let html = editor.runCommand('gjs-get-inlined-html')
        let links = regex.exec(html)
        if(links) for(let l=0;l<links.length;l++){
          console.log('link',links[l]);
          if(links[l].includes(' ')) links[l] = links[l].split(' ')[0];
          if(!links[l].includes('.jpg') && !links[l].includes('.jpeg') && !links[l].includes('.png') && !links[l].includes('.gif') && !links[l].includes('.webp') && !links[l].includes('.svg') && !links[l].includes('cdn.cobaltfairy.online')){
            if(links[l].includes('"')) links[l] = links[l].split('"')[0];
            if(!links[l].startsWith('http')) links[l] = `http://${links[l]}`;
            html = html.replace(links[l],`${PIXEL_URL}/custom/redir?${(this.state.editorType=="campaign" ? 'cid' : 'tid')}=${this.state.uuid}&uid={{pixel_uid}}&r=${links[l]}`);
          }
        }
        try{
          let sesresp = await ApiService.post('custom/assignSES',{uuid:this.state.entity.attributes.uuid,subject:this.state.entity.attributes.subject ? this.state.entity.attributes.subject : this.state.entity.attributes.name,design:`<html><head>${requiredStyles}</head>${html}<img src=${pixel}/></html>`},user.jwt);
          console.log('sesresp',sesresp,this.state.entity.attributes.uuid);
          if(resp && resp.data && resp.data.data && resp.data.data.id && resp.data.data.id==this.state.entity.id){
            mSwal.fire({icon:'success',timer:800});
            this.context.setLoading_(false);
            }else{
            this.context.setLoading_(false);
            mSwal.fire({icon:'warning',title:'Campaign save failed :(',text:'The app faced a problem saving your changes. Please try again. If the problem persists please contact the support team.'})
            }
          }catch(error){
              console.log(error);
              this.context.setLoading_(false);
          mSwal.fire({icon:'error',text:'Failed to save campaign body. If this error persists please contact our support team.'})
        }
      
        
      }
    })
    
  }

  
  setTimeout(()=>{
    document.querySelector(".gjs-frame").contentWindow.document.querySelector("head").innerHTML += `
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
          body, *{
            font-family:'Playfair Display', arial
          }
          .button{padding: 10px 25px;background-color:#333;border: 1ox solid #333;border-radius:10px;color:white}
          a{  text-decoration-line: none!important; }
          #cf-two-cols img{ margin-right:20px;}

        </style>
      `},1000)

  window.gjs = editor;


  },100)
  return (
    <div>
      <div id="gjs" style={{minHeight:'98vh'}}>
      </div>
    </div>
  )
}

}

export default withParams(CFMailEditor)
